<template>
    <div>
        <v-row justify="center" class="pa-4 px-16 hidden-sm-and-down">
            <v-col md="12" lg="12">
                <v-row>
                    <v-col md="4" lg="6">
                        <v-row class="fill-height">
                            <v-col md="2" lg="1">
                    
                                <v-img src="../assets/images/cci.png" width="100%" height="100%"></v-img>
                            </v-col>
                            <v-col md="10" lg="11" align-self="center">
                                <span>Cyber Craft Innovations </span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="8" lg="6">
                        <v-row>
                            <v-col md="3" lg="3">
                                <v-btn variant="plain">Features </v-btn>
                            </v-col>
                            <v-col md="3" lg="3">
                                <v-btn variant="plain">About </v-btn>
                            </v-col>
                            <v-col md="3" lg="3">
                                <v-btn variant="plain" @click="scrollToSupport">Support </v-btn>
                            </v-col>
                            <v-col md="3" lg="3">
                                <v-btn color="#5444BC">Download </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>    
    </div>

    <div class="hidden-md-and-up">
        <v-app-bar>
            <v-row justify="center" class="fill-height">
                <v-col cols="12" sm="12" class="text-center">
                    <v-row justify="center">
                        <v-col cols="2" sm="1" class="pr-0">
                            <v-avatar>
                            <v-img src="../assets/images/cci.png"></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col cols="6" sm="3" class="pl-0 pl-sm-1" align-self="center">
                            <span style="font-size: 20px;">Cyber Craft Innovations</span>
                        </v-col>
                    
                    </v-row>
                </v-col>
            </v-row>
            <v-app-bar-nav-icon @click="mobileMenu = !mobileMenu">
                <v-icon>mdi-menu</v-icon>
            </v-app-bar-nav-icon>
        </v-app-bar>
        <v-navigation-drawer
        location="right"
        app
        temporary
        v-model="mobileMenu"
        style="position: fixed;"
        >
        <v-list>
            <v-row justify="center" class="pl-1">
                <v-col cols="12" sm="12">
                    <v-list-item-title>
                        <v-btn variant="plain">Features </v-btn>
                    </v-list-item-title>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-list-item-title>
                        <v-btn variant="plain">About </v-btn>
                    </v-list-item-title>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-list-item-title>
                        <v-btn variant="plain" @click="scrollToSupport">Support </v-btn>
                    </v-list-item-title>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-list-item-title>
                        <v-btn color="#5444BC">Download </v-btn>
                    </v-list-item-title>
                </v-col>
            </v-row>
        </v-list>
        </v-navigation-drawer>
    </div>


</template>
<script>
export default {
  data() {
    return {
      mobileMenu: false,
    };
  },
  methods: {
    scrollToSupport() {
      // Assuming you have a route named 'supportpage'
      this.$router.push({ name: 'supportpage' });

      // Scroll to the corresponding element with an ID 'support-section'
      const supportSection = document.getElementById('support-section');
      if (supportSection) {
        window.scrollTo({
          top: supportSection.offsetTop,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>