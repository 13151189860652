import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/Header',
    name: 'Homepage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HeaderPage.vue')
  },
  {
    path: '/Home',
    name: 'HomePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HomePage.vue')
  },
  {
    path: '/Services',
    name: 'AppServices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AppServices.vue')
  },
  {
    path: '/',
    name: 'FinalPage',

    component: () => import(/* webpackChunkName: "about" */ '../views/FinalPage.vue')
  },
  {
    path: '/Key',
    name: 'KeyFeatures',

    component: () => import(/* webpackChunkName: "about" */ '../views/KeyFeatures.vue')
  },
  {
    path: '/opt',
    name: 'OptimalEfficiency',

    component: () => import(/* webpackChunkName: "about" */ '../views/OptimalEfficiency.vue')
  },
  {
    path: '/Download',
    name: 'DownloadPage',

    component: () => import(/* webpackChunkName: "about" */ '../views/DownloadPage.vue')
  },
  {
    path: '/footer',
    name: 'footerpage',

    component: () => import(/* webpackChunkName: "about" */ '../views/FooterPage.vue')
  },
  {
    path: '/supportpage',
    name: 'supportpage',

    component: () => import(/* webpackChunkName: "about" */ '../views/SupportPage.vue')
  },
  {
    path: '/termsofservice',
    name: 'termsofservice',

    component: () => import(/* webpackChunkName: "about" */ '../views/TermsandService.vue')
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',

    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacypolicyPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
